import React, { useState, useEffect, useContext, useMemo } from 'react'
import Layout from 'components/AdminDashboard/Layout/Layout'
import {
  Row,
  Column,
  ContentBox,
} from 'components/AdminDashboard/Grid/Grid.styles'
import { Card, CardHeader } from 'components/AdminDashboard/Card/Card.styles'
import Button from 'components/Button/Button.styles'
import ComponentWrapper from 'components/ComponentWrapper/ComponentWrapper.styles'
import { ProjectContext } from 'context/ProjectContext/ProjectContext'

import { Text } from '@stokr/components-library'
import NAVModal from 'components/NAVModal/NAVModal'
import Table from 'components/AdminDashboard/Table/Table'
import { TrashIcon } from 'components/AdminDashboard/TeamMemberItem/TeamMemberItem.styles'
import AlertModal from 'components/AdminDashboard/AlertModal/AlertModal'

export const orderStates = {
  ASC: 'ASCENDING',
  DESC: 'DESCENDING',
}

const NAVPriceHistoryColumns = [
  {
    key: 'date',
    accessor: 'date',
    label: 'Date',
    width: '40%',
  },
  {
    key: 'price',
    accessor: 'price',

    label: 'NAV price',
    width: '40%',
  },
  {
    key: 'buttons',
    accessor: 'buttons',

    label: 'Options',
    width: '20%',
  },
]

function AdminNAV() {
  const { deleteNAVPrice, NAVPrices } = useContext(ProjectContext)

  //modal states
  const [isNAVModalOpen, setIsNAVModalOpen] = useState(false)

  //delete nav states
  const [NAVPriceToDelete, setNAVPriceToDelete] = useState(null)
  const [successMessage, setSuccessMessage] = useState()
  const [errorMessage, setErrorMessage] = useState()

  const onDeleteNAV = async () => {
    let navPrice = NAVPrices.find((x) => x._id === NAVPriceToDelete)
    try {
      await deleteNAVPrice(NAVPriceToDelete, navPrice.assetId)
      setNAVPriceToDelete(null)
      setSuccessMessage('NAV price was successfully deleted')

      setTimeout(() => {
        setSuccessMessage()
      }, 3000)
    } catch (error) {
      setErrorMessage('Oops, something went wrong. Please try again.')
    }
  }

  return (
    <Layout currentPage="nav">
      <Row mainDashboard>
        <Column>
          <ContentBox>
            <Row>
              <Column>
                <Card>
                  <CardHeader>
                    <Text
                      style={{ marginBottom: 32, marginRight: 20 }}
                      withMarginBottom
                      withMarginRight
                    >
                      <h3>NAV</h3>
                    </Text>
                  </CardHeader>

                  {NAVPrices ? (
                    <>
                      <Text>
                        <p>From here you can manage NAV price history</p>
                      </Text>

                      <ComponentWrapper noPaddingHorizontal>
                        <Button onClick={() => setIsNAVModalOpen(true)}>
                          Update NAV
                        </Button>
                      </ComponentWrapper>

                      <Text success>
                        <p style={{ fontSize: 16, marginBottom: 32 }}>
                          {successMessage}
                        </p>
                      </Text>

                      <ComponentWrapper
                        noPaddingTop
                        noPaddingBottom
                        noPaddingHorizontal
                      >
                        <Table
                          //maxHeight={400}
                          columns={NAVPriceHistoryColumns}
                          data={NAVPrices?.map((row) => {
                            return {
                              ...row,

                              buttons: (
                                <>
                                  <TrashIcon
                                    onClick={() => setNAVPriceToDelete(row._id)}
                                  />
                                </>
                              ),
                            }
                          })}
                        />
                      </ComponentWrapper>
                    </>
                  ) : (
                    <Text>
                      <p>This project doesnt have NAV price history</p>
                    </Text>
                  )}
                </Card>
              </Column>
            </Row>
          </ContentBox>
        </Column>
      </Row>

      {/* NAV MODAL */}
      {isNAVModalOpen && (
        <NAVModal
          isModalOpen={isNAVModalOpen}
          onModalClose={() => setIsNAVModalOpen(false)}
          title="UPdate Nav"
          caption={`By clicking "Submit", I confirm this NAV per unit and date are accurate.`}
          addNAV
          //onSubmit={handleUpdateNAV}
          //errorMessage={errorMessageModal}
        />
      )}

      {/* DELETE NAV MODAL */}
      <AlertModal
        isOpen={NAVPriceToDelete != null}
        onModalClose={() => {
          setNAVPriceToDelete(null)
        }}
        onButtonClick={onDeleteNAV}
        warningText="Are you sure you want to remove this NAV price?"
        buttonText="Remove"
      />
    </Layout>
  )
}

export default AdminNAV
