import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { ThemeProvider } from 'styled-components/macro'

import GlobalStyle from 'styles/global'
import { getMedia } from 'styles/rwd'
import theme from 'styles/theme'

import Sidebar from 'components/AdminDashboard/Sidebar/Sidebar'
import { iconsMap } from 'components/Icon/Icon.style'
import SidebarItem from 'components/AdminDashboard/SidebarItem/SidebarItem'
import { Footer, Header, AuthConsumer } from '@stokr/components-library'
import { PageWrapper } from './Layout.styles'
import { withRouter } from 'utils/withRouter'
import { ProjectContext } from 'context/ProjectContext/ProjectContext'

const Layout = (props) => {
  const [isSidebarExpanded, setisSidebarExpanded] = React.useState(true)
  const [activeMenu, setActiveMenu] = React.useState(null)
  const { project } = React.useContext(ProjectContext)

  const timeout = React.useRef()

  const handleOutsideMenuClick = (e) => {
    setActiveMenu(false)
  }

  const activateMenu = () => {
    setActiveMenu(null)
  }

  // componentWillMount() {
  //   this.setState({
  //     isSidebarExpanded: !this.checkIsMobile(),
  //   })
  // }

  React.useEffect(() => {
    window.addEventListener('resize', onResize)
    onResizeTimeout()

    return () => {
      window.removeEventListener('resize', onResize)
    }
  }, [])

  const sidebarHandler = () => {
    setisSidebarExpanded(!isSidebarExpanded)
  }

  const onResize = () => {
    clearTimeout(timeout.current)
    timeout.current = setTimeout(onResizeTimeout, 10)
  }

  const onResizeTimeout = () => {
    setisSidebarExpanded(!checkIsMobile())
  }

  const checkIsMobile = () => !window.matchMedia(getMedia('MLarge')).matches

  const { children, currentPage, navigate } = props

  return (
    <ThemeProvider theme={{ ...theme }}>
      <PageWrapper isSidebarExpanded={isSidebarExpanded}>
        <GlobalStyle />
        <Sidebar isSidebarExpanded={isSidebarExpanded}>
          <SidebarItem
            icon={iconsMap.overview}
            title="Overview"
            handleClick={() => navigate('/overview')}
            isActive={currentPage === 'overview'}
            isExpanded={isSidebarExpanded}
          />

          <SidebarItem
            icon={iconsMap.tokenHolder}
            title="Investor Register"
            handleClick={() => navigate('/token-holder')}
            isActive={currentPage === 'token-holder'}
            isExpanded={isSidebarExpanded}
          />
          <SidebarItem
            icon={iconsMap.hamburger}
            title="Private investors"
            handleClick={() => navigate('/private-investor')}
            isActive={currentPage === 'private-investor'}
            isExpanded={isSidebarExpanded}
          />

          {project && project.type === 'fund' && (
            <SidebarItem
              icon={iconsMap.tokenSales}
              title="NAV"
              handleClick={() => navigate('/nav')}
              isActive={currentPage === 'nav'}
              isExpanded={isSidebarExpanded}
            />
          )}

          <SidebarItem
            icon={iconsMap.updates}
            title="Updates"
            handleClick={() => navigate('/updates')}
            isActive={currentPage === 'updates'}
            isExpanded={isSidebarExpanded}
          />
          <SidebarItem
            icon={iconsMap.comments}
            title="Comments"
            handleClick={() => navigate('/comments')}
            isActive={currentPage === 'comments'}
            isExpanded={isSidebarExpanded}
          />
          <SidebarItem
            icon={iconsMap.teams}
            title="Admin"
            handleClick={() => navigate('/team')}
            isActive={currentPage === 'team'}
            isExpanded={isSidebarExpanded}
          />
          <SidebarItem
            icon={iconsMap.analytics}
            title="Analytics"
            handleClick={() => navigate('/analytics')}
            isActive={currentPage === 'analytics'}
            isExpanded={isSidebarExpanded}
          />
          <SidebarItem
            icon={iconsMap.cog}
            title="Settings"
            handleClick={() => navigate('/settings')}
            isActive={currentPage === 'settings'}
            isExpanded={isSidebarExpanded}
          />
        </Sidebar>
        <AuthConsumer>
          {({ user, avatar, logoutUser }) => (
            <Header
              isSidebarExpanded={isSidebarExpanded}
              sidebarHandler={sidebarHandler}
              withSidebar
              userPhoto={avatar}
              user={user}
              logoutUser={logoutUser}
            />
          )}
        </AuthConsumer>
        <div onClick={handleOutsideMenuClick}>{children}</div>
        <Footer
          color="red"
          isSidebarExpanded={isSidebarExpanded}
          hasSidebar={true}
        />
      </PageWrapper>
    </ThemeProvider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  currentPage: PropTypes.string,
}

Layout.defaultProps = {
  currentPage: '',
}

export default withRouter(Layout)
